import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { StyledText } from '../components_elements';
import { Hidden, Grid, Button } from '@material-ui/core';
import Carousel from '@brainhubeu/react-carousel';
import '../styles/brainhubeu_react_carousel.css';
import breakpoint from '../app_utils/Breakpoints';

interface Props {
    images: string[];
}

const cssCarouselWrapper = css`
    max-width: 1080px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    margin-top: 15px;
`;

const CarouselItem: React.FC<{ image: string }> = ({ image }) => {
    return (
        <div
            css={css`
                max-width: 400px;
                padding-bottom: 20px;
            `}>
            <img
                css={css`
                    max-height: 350px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;

                    @media ${breakpoint.md} {
                        max-height: 600px;
                    }
                `}
                src={image}
                alt=''
            />
        </div>
    );
};

const ResponsiveCarouselAuto: React.FC<Props> = ({ images }) => {
    const [value, setValue] = React.useState<number>(0);
    const onChange = (value: number) => {
        setValue(value);
        console.log(value);
    };
    return (
        <div>
            <Grid container justify='center' css={cssCarouselWrapper}>
                <Grid item xs={10} md={10}>
                    <Carousel
                        value={value}
                        autoPlay={5000}
                        animationSpeed={1000}
                        infinite
                        stopAutoPlayOnHover
                        draggable={false}
                        onChange={onChange}
                        slidesPerPage={1}
                        breakpoints={{
                            576: {
                                slidesPerPage: 1,
                                slidesPerScroll: 1,
                                clickToChange: true,
                                animationSpeed: 1000,
                                infinite: true
                            }
                        }}>
                        {images.map((item, index) => (
                            <CarouselItem key={index} image={item} />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </div>
    );
};

ResponsiveCarouselAuto.defaultProps = {};

export default ResponsiveCarouselAuto;
