import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core';

interface Props {}

const ZEnrollButton: React.FC<Props> = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Button className={classes.button} component={Link} to='/enroll' color='primary'>
                    Enroll Baitshop
                </Button>
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        padding: '10px 35px',
        marginBottom: '10px',
        backgroundColor: '#0984e3',
        color: 'white',

        '&:hover, &:focus': {
            backgroundColor: '#0984e3',
            color: 'white',
        },
    },
}));

export default ZEnrollButton;
