import * as React from 'react';
import Navbar from '../../components/Navbar';
import { Grid } from '@material-ui/core';
import { StyledText } from '../../components_elements';

import ImgFishingReport1 from '../../assets/images/iphone_landing.png';
import ImgFishingReport2 from '../../assets/images/iphone_landing.png';
import ImgFishingReport3 from '../../assets/images/iphone_landing.png';
import ImgFishingReport4 from '../../assets/images/iphone_landing.png';
import ImgFishingReport5 from '../../assets/images/iphone_landing.png';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 40px;
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItems: ICarouselItem[] = [
    {
        body:
            '1. Start by opening your favorite bait shop, and clicking on the Reports tab.  You can browse reports by simply going from Bait Shop to Bait Shop looking at report or...',
        image: '/images/angler_fishing_report_1.png'
    },
    {
        body: '2. You can click on the Notify Me button in any bait shop...',
        image: '/images/angler_fishing_report_2.png'
    },
    {
        body: '3. Not only will you get a notification when a new report is posted.',
        image: '/images/angler_fishing_report_3.png'
    },
    {
        body: '4. You can click the notification and see the report.',
        image: '/images/angler_fishing_report_4.png'
    }
];

const AnglerFishingReportPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar angler />

            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Fishing Reports
            </StyledText>
            <Grid css={cssPage}>
                <StyledText>
                    Reading fishing reports and be one of the most enjoyable activities for an
                    Angler. Not only do we love driving ourselves crazy by watching the success of
                    others, but they also provide exceptionally valuable information for anglers.
                    Ranging from what fish is biting on what bait, to are Flounder running, or are
                    the Big Tarpon migrating through the keys yet, are walleyes shallow or deep, are
                    bass spawning...etc.!
                </StyledText>
                <StyledText>
                    Often the best source of fishing reports are the Bait Shops. We make it simple
                    for Bait Shops to post fishing reports and we make it simple for anglers to
                    follow, even set up notifications for when new reports are posted.
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 70px;
                    `}>
                    How it works.
                </StyledText>
                <ResponsiveCarouselNext CarouselItems={CarouselItems}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter />
        </div>
    );
};

AnglerFishingReportPage.defaultProps = {};

export default AnglerFishingReportPage;
