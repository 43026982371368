import * as React from 'react';
import { Grid, Hidden } from '@material-ui/core';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { StyledText } from '../../components_elements';
import Navbar from '../../components/Navbar';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';
import breakpoint from '../../app_utils/Breakpoints';
import ZEnrollButton from '../../components/ZEnrollButton';

interface Props {}

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItemsTop: ICarouselItem[] = [
    {
        body: '1. Click on add',
        image: '/images/baitshop_avalability_1.png',
    },
    {
        body: '2. Select the baits you carry.  Notice there is a search feature.',
        image: '/images/baitshop_avalability_2.png',
    },
    {
        body: '3. click on the red pencil by a bait',
        image: '/images/baitshop_avalability_3.png',
    },
    {
        body: '4. Click on Edit Availability.',
        image: '/images/baitshop_avalability_4.png',
    },
    {
        body: '5. Move the toggles for bait you normally stock.',
        image: '/images/baitshop_avalability_5.png',
    },
    {
        body: '6. Move the toggle for the bait you have in stock',
        image: '/images/baitshop_avalability_6.png',
    },
    {
        body: '7. You are publishing!',
        image: '/images/baitshop_avalability_7.png',
    },
    {
        body: `8. This is what the Angler see, it's updated instantly`,
        image: '/images/baitshop_avalability_8.png',
    },
];
const CarouselItemsBottom: ICarouselItem[] = [
    {
        body: '1. Click on magnifying glass',
        image: '/images/availability_bottom_1.png',
    },
    {
        body: '2. Click on select search area.',
        image: '/images/availability_bottom_2.png',
    },
    {
        body: '3. Place the pin on the map.',
        image: '/images/availability_bottom_3.png',
    },
    {
        body: '4. Click on change bait choices.',
        image: '/images/availability_bottom_4.png',
    },
    {
        body: '5. Click on Fresh/Salt Water.',
        image: '/images/availability_bottom_5.png',
    },
    {
        body: '6. Click on the bait your are looking for, you can choose multiple.',
        image: '/images/availability_bottom_6.png',
    },
    {
        body: '7. Select Live|Fresh|Frozen, click on save',
        image: '/images/availability_bottom_7.png',
    },
    {
        body: `GREEN = Shop has everything, YELLOW = Shop has one or more, RED = Shop does not have what you are looking for`,
        image: '/images/availability_bottom_8.png',
    },
    {
        body: '9. Click on the pin.',
        image: '/images/availability_bottom_9.png',
    },
    {
        body: '10. Open Bait Shop and view details of the bait the shop has RIGHT NOW!!.',
        image: '/images/availability_bottom_10.png',
    },
];

const BaitShopAvailabilityPage: React.FC<Props> = () => {
    const cssPageIntro = css`
        max-width: 1080px;
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
        margin-top: 80px;
    `;
    const cssGridSection = css`
        padding: 80px 0px;
        background: linear-gradient(#ffffff, #f8f8f8);
    `;
    const cssGridWrapper = css`
        padding: 100px 30px;
        max-width: 1080px;
        margin: auto;

        img {
            max-height: 350px;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @media ${breakpoint.md} {
                max-height: 600px;
            }
        }
    `;
    return (
        <div>
            <Navbar baitshop />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Availability
            </StyledText>

            <StyledText css={cssPageIntro}>
                Focus on the customer in front of you, not on answering the phone! BaitFinder
                enables Bait Shops to publish their bait availability for free. It only takes a few
                minutes to set up, and less than a second to update.
            </StyledText>

            <Grid css={cssGridSection}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    Set up your bait
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    (only Admin Role has permission to add bait)
                </StyledText>
                <ResponsiveCarouselNext CarouselItems={CarouselItemsTop}></ResponsiveCarouselNext>

                <ZEnrollButton />
            </Grid>

            <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
                <Grid item md={7} xs={12}>
                    <StyledText subheading textAlign='center'>
                        BaitFinder and BaitFinder Admin are FREE mobile apps
                    </StyledText>

                    <StyledText textAlign='center'>
                        We at BaitFinder know how busy Bait Shops are, that is why we optimized the
                        maintenance of advertising you bait availability....it's a single click,
                        takes less than a second.
                    </StyledText>
                    <StyledText textAlign='center'>Maintain your Bait Availability</StyledText>
                    <StyledText
                        textAlign='center'
                        css={css`
                            margin-top: -20px;
                        `}>
                        (Admin or Associate role can maintain availability)
                    </StyledText>
                    <StyledText textAlign='center'>Green = In Stock</StyledText>
                    <StyledText textAlign='center'>Red =Out of Stock</StyledText>
                    <StyledText textAlign='center'>Gray = You don't stock</StyledText>
                </Grid>

                <Grid item xs={12} md={5}>
                    <img src='/images/baitshop_home_app_section.png' alt='' />
                </Grid>
            </Grid>

            <ZEnrollButton />

            <SectionFooter isBaitshop />
        </div>
    );
};

BaitShopAvailabilityPage.defaultProps = {};

export default BaitShopAvailabilityPage;
