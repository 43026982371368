import * as React from 'react';
import Navbar from '../../components/Navbar';
import { Container, Grid, Typography } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import StyledText from '../../components_elements/StyledText';

import ImgFishingReport1 from '../../assets/images/iphone_landing.png';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 40px;
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItems: ICarouselItem[] = [
    {
        body: '1. The R signifies the bait shop will accept reservations for that specific bait.',
        image: '/images/reservation_1.png'
    },
    {
        body:
            "2. Click on the blue carts...the red carts don't have pricing, so you will need to talk to the bait shop",
        image: '/images/reservation_2.png'
    },
    {
        body:
            '3.  Use the + or - to select the quantity you want to order....they will be placed in your cart...you may choose multiple baits.',
        image: '/images/reservation_3.png'
    },
    {
        body: '4. Click on the cart to view your cart',
        image: '/images/reservation_4.png'
    },
    {
        body:
            '5.  We will calculate your total bill, there is a fee for reservations, this is to cover costs at both BaitFinder and the Bait Shop. ',
        image: '/images/reservation_5.png'
    },
    {
        body: '6. Click on Set Pick up Time',
        image: '/images/reservation_6.png'
    },
    {
        body: '7. Pick the Pick up date',
        image: '/images/reservation_7.png'
    },
    {
        body: `8. Set pick up time...there is a one hour grace period`,
        image: '/images/reservation_8.png'
    },
    {
        body:
            '9. We remind you the Bait Shops will do their best, but live bait is unpredictable.  We will track bait shop performance.  Click OK to continue, Cancel to go back.',
        image: '/images/reservation_9.png'
    },
    {
        body: '10. You get a summary of your order...click proceed to pay',
        image: '/images/reservation_10.png'
    },
    {
        body: '11. If you have not entered a credit card, click add a card',
        image: '/images/reservation_11.png'
    },
    {
        body:
            '12. Enter your credit card information. This information never goes through BaitFinder servers, rather it goes directly to Stripe the same company that process for Uber, Lyft, and Amazon!!',
        image: '/images/reservation_12.png'
    },
    {
        body: '13. Enter your CVC number, we always have you enter this as it acts as a password.',
        image: '/images/reservation_13.png'
    },
    {
        body:
            '14. The order is placed. Now the Bait Shop will review the reservation and Accept with a confidence level or reject with aa reason.',
        image: '/images/reservation_14.png'
    },
    {
        body: '15.  You can view the status of the reservation on the reservation tab.',
        image: '/images/reservation_15.png'
    },
    {
        body:
            '16. Bait Shop Accepts with a confidence level, when Bait Shop Accepts, you will get a notification',
        image: '/images/reservation_16.png'
    },
    {
        body: '17. If accepted the reservation will move to Accepted',
        image: '/images/reservation_17.png'
    },
    {
        body: `18. If you click on the reservation, you go to the detail and you can see the Bait Shops confidence level they will be able to deliver.`,
        image: '/images/reservation_18.png'
    },
    {
        body: '19.  If Rejected, it will move to the history tab, and if you click on it.',
        image: '/images/reservation_19.png'
    },
    {
        body: '20. You can see the reason the bait shop',
        image: '/images/reservation_20.png'
    },
    {
        body:
            '21. As the pick up time approaches, between 12-24hours before pick up time, the Bait Shop confirms the order.  You may cancel a reservation at any time BEFORE the Bait Shop confirms. Once the Bait Shop confirms, you must request a refund, and it is up to the Bait Shop to determine the refund amount if any.  You will get a notification when the reservation is confirmed.',
        image: '/images/reservation_21.png'
    },
    {
        body: `22. You can view your reservation status as confirmed on the reservations tab.`,
        image: '/images/reservation_22.png'
    },
    {
        body:
            '23. When you show up to pick up your bait, the bait shop may ask for your Password to verify your identity.',
        image: '/images/reservation_23.png'
    },
    {
        body:
            '24. If you show up, and the bait shop does not have the bait, you can initiate the refund process.  If there is a disagreement, you can raise an issue to BaitFinder. (exceptionally rare)',
        image: '/images/reservation_24.png'
    },
    {
        body:
            '25. When the bait is delivered, the order will move to the history tab. The transaction is complete…in the future, like UBER, Lyft, and other apps, you will be given the opportunity to rate your experience, and tip the Bait Shop.',
        image: '/images/reservation_25.png'
    }
];

const AnglerReservationPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar angler />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Reservations
            </StyledText>
            <Grid css={cssPage}>
                <StyledText>
                    Anglers have been asking for Bait Shops to reserve their bait for decades. There
                    are many good reasons why reservations have not been available until now
                    (unpredictable catch, bait can die, freezers fail). BaitFinder has worked with
                    Bait Shops to solve these problems, not all bait shops accept reservations yet,
                    but over time it will expand. Talk to your favorite bait shop to help us help
                    you!
                </StyledText>
                <StyledText>
                    Bait is one of the most important things in a successful fishing trip (behind
                    weather and your boat starting)...on a trip that costs hundreds of dollars to
                    begin with a few bucks to ensure the bait you want is available at the time you
                    want is worth it every time~
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 70px;
                    `}>
                    How it works.
                </StyledText>

                <ResponsiveCarouselNext CarouselItems={CarouselItems}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter />
        </div>
    );
};

AnglerReservationPage.defaultProps = {};

export default AnglerReservationPage;
