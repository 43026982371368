import * as React from 'react';
import { Grid, Button } from '@material-ui/core';
import Navbar from '../../components/Navbar';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

interface Props {}

const getStripeId = async (code: any) => {
    const response = await axios.post('https://n6imr.sse.codesandbox.io/val', {
        code
    });

    const { status, userId } = response.data;

    console.log(userId);
};

const StripeSuccess: React.FC<Props> = () => {
    let history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);
    const stripeCode = searchParams.get('code');

    return (
        <div>
            {console.log(stripeCode)}
            <Navbar angler />
            <Grid container justify='center'>
                Success
            </Grid>
            <Button onClick={() => getStripeId(stripeCode)}>Get Stripe ID</Button>
        </div>
    );
};

StripeSuccess.defaultProps = {};

export default StripeSuccess;
