import * as React from 'react';
import { Grid, TextField, Button, Card, Typography, Paper } from '@material-ui/core';
import PhotoModalEnroll from '../../assets/images/modal_enroll_fisher_men.jpg';
import axios from 'axios';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import breakpoint from '../../app_utils/Breakpoints';
import { Controller, useForm } from 'react-hook-form';
import * as emailjs from 'emailjs-com';
import Navbar from '../../components/Navbar';

interface Props {}

type FormData = {
    name: string;
    email: string;
    phoneNumber: string;
    baitshopAddressStreet: string;
    baitshopAddressCity: string;
    baitshopAddressState: string;
    baitshopAddressZip: string;
    baitshopName: string;
    baitshopPhoneNumber: string;
    bestTimeToContact: string;
    additionalNotes: string;
};
const cssPaperSuccess = css`
    overflow: hidden;
    padding: 50px;
    height: 350px;
    width: 500px;

    .button {
        margin-top: 30px;
    }
    p {
        display: block;
        width: 100%;
        text-align: center;
    }

    img {
        height: 100px;
        display: block;
    }
`;

const BaitShopEnrollPage: React.FC<Props> = () => {
    const { register, errors, handleSubmit, control } = useForm<FormData>({});
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const onSubmit = async (data: FormData) => {
        setIsSubmitting(true);

        const result = await axios.post(
            'https://us-central1-baitfinder-94065.cloudfunctions.net/httpsEnrollEmail/api/v1/send-mail',
            {
                ...data,
            }
        );
        if (result.status === 200) {
            setShowSuccess(true);
        }
        console.log(data);
        console.log(`REEEEEEEE   ${result.status}`);

        setIsSubmitting(false);
    };
    return (
        <div>
            <Navbar baitshop />
            <Grid container justify='center' alignItems='center'>
                <Grid
                    container
                    justify='center'
                    alignItems='center'
                    css={css`
                        max-width: 720px;
                        margin: auto;
                        padding: 20px;

                        @media ${breakpoint.md} {
                            padding: 50px;
                        }
                    `}>
                    {!showSuccess && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Card>
                                <div
                                    css={css`
                                        background-image: url(${PhotoModalEnroll});
                                        background-position: center center;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        height: 150px;
                                        width: 100%;

                                        @media ${breakpoint.md} {
                                            height: 150px;
                                        }
                                    `}
                                />
                                <Grid
                                    container
                                    item
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                    xs={12}>
                                    <Typography
                                        variant='body1'
                                        css={css`
                                            text-align: center;

                                            margin-top: 20px;
                                            margin-bottom: 10px;
                                        `}>
                                        Would you like to start publishing your bait shops bait
                                        availability?
                                    </Typography>
                                    <Typography
                                        css={css`
                                            text-align: center;
                                        `}
                                        variant='subtitle2'>
                                        Complete the form below to initiate the ownership validation
                                        process!
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    justify='center'
                                    css={css`
                                        padding: 20px;

                                        @media ${breakpoint.md} {
                                            padding: 20px 40px 40px 40px;
                                        }
                                    `}>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='name'
                                                    label='Your Name'
                                                    inputRef={register({ required: true })}
                                                    placeholder='Joe'
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={errors.name ? true : false}
                                                    helperText={
                                                        errors?.name?.type === 'required' &&
                                                        'This is required'
                                                    }
                                                />
                                            }
                                            name='name'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='email'
                                                    label='Your Email'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={errors.email ? true : false}
                                                    helperText={
                                                        errors?.email?.type === 'required' &&
                                                        'This is required'
                                                    }
                                                />
                                            }
                                            name='email'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='phoneNumber'
                                                    label='Your Phone Number'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={errors.phoneNumber ? true : false}
                                                    helperText={
                                                        errors?.phoneNumber?.type === 'required' &&
                                                        'This is required'
                                                    }
                                                />
                                            }
                                            name='phoneNumber'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='baitshopName'
                                                    label='Baitshop Name'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={errors.baitshopName ? true : false}
                                                    helperText={
                                                        errors?.baitshopName?.type === 'required' &&
                                                        'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopName'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='baitshopStreet'
                                                    label='Baitshop Street'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        errors.baitshopAddressStreet ? true : false
                                                    }
                                                    helperText={
                                                        errors?.baitshopAddressStreet?.type ===
                                                            'required' && 'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopAddressStreet'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    label='Baitshop City'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        errors.baitshopAddressCity ? true : false
                                                    }
                                                    helperText={
                                                        errors?.baitshopAddressCity?.type ===
                                                            'required' && 'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopAddressCity'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='baitshopAddressState'
                                                    label='Baitshop State'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        errors.baitshopAddressState ? true : false
                                                    }
                                                    helperText={
                                                        errors?.baitshopAddressState?.type ===
                                                            'required' && 'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopAddressState'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='baitshopAddressZip'
                                                    label='Baitshop Zip'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={errors.baitshopAddressZip ? true : false}
                                                    helperText={
                                                        errors?.baitshopAddressZip?.type ===
                                                            'required' && 'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopAddressZip'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='baitshopPhoneNumber'
                                                    label='Baitshop Phone Number'
                                                    inputRef={register({ required: true })}
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        errors.baitshopPhoneNumber ? true : false
                                                    }
                                                    helperText={
                                                        errors?.baitshopPhoneNumber?.type ===
                                                            'required' && 'This is required'
                                                    }
                                                />
                                            }
                                            name='baitshopPhoneNumber'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='bestTimeToContact'
                                                    label='Best Time To Contact You'
                                                    inputRef={register}
                                                    margin='dense'
                                                    variant='outlined'
                                                />
                                            }
                                            name='bestTimeToContact'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            as={
                                                <TextField
                                                    id='additionalNotes'
                                                    multiline
                                                    rows={4}
                                                    label='Additional Notes'
                                                    inputRef={register}
                                                    margin='dense'
                                                    variant='outlined'
                                                />
                                            }
                                            name='additionalNotes'
                                            control={control}
                                            defaultValue=''
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        direction='row'
                                        justify='center'
                                        alignItems='center'
                                        xs={12}>
                                        <Button
                                            css={css`
                                                width: 100%;
                                                margin: 12px 0;
                                            `}
                                            type='submit'
                                            disabled={isSubmitting}
                                            variant='outlined'
                                            color='primary'>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </form>
                    )}
                    {showSuccess && (
                        <Paper css={cssPaperSuccess}>
                            <Grid container justify='center' alignItems='center'>
                                <Grid item xs={12} container justify='center'>
                                    <img src='/images/success.png' alt='' />

                                    <p>Email Sent!</p>
                                </Grid>
                                <Grid item xs={12} container justify='center' className='button'>
                                    <Button
                                        type='submit'
                                        variant='outlined'
                                        onClick={() => setShowSuccess(false)}>
                                        Enroll another BaitShop
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};
export default BaitShopEnrollPage;
