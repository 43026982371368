/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

type IProps = {
    fontSize?: string | number;
    textAlign?: string;
    heading?: boolean;
    subheading?: boolean;
    padding?: string;
    margin?: string;
};

const StyledText = styled.p<IProps>`
    letter-spacing: 0.2px;
    font-weight: 300;
    color: #444;
    line-height: 2.2rem;
    font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : '17px')};
    margin: 1rem 0px;

    ${(props: IProps) =>
        props.heading &&
        css`
            line-height: 3rem;
            font-size: 42px;
            font-weight: 700;
            margin-bottom: 2rem;
            margin-top: 0.625rem;
            letter-spacing: 0.5px;
            color: #122239;
        `}

    ${(props: IProps) =>
        props.subheading &&
        css`
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 2rem;
            margin-top: 0.625rem;
            letter-spacing: 0.5px;
            color: #122239;
        `};

    text-align: ${(props: IProps) => (props.textAlign ? props.textAlign : 'left')};
    padding: ${(props: IProps) => props.padding && props.padding};
    margin: ${(props: IProps) => props.margin && props.margin};
`;

export default StyledText;
