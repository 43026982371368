import * as React from 'react';
import Navbar from '../../components/Navbar';
import { Container, Grid, Typography } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import StyledText from '../../components_elements/StyledText';

import ImgFishingReport1 from '../../assets/images/iphone_landing.png';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 40px;
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItemsStripe: ICarouselItem[] = [
    {
        body:
            '1. Stripe is the largest processor of credit cards in the world.  They have a simple intuitive way to create an account and enable BaitFinder to deposit money directly in your account.',
        image: '/images/baitshop_reservation_stripe_1.png'
    },
    {
        body:
            '2. On the Info tab of the admin app, click Link, this launches a Stripe powered wizard, which will ask you a few simple questions, and either bank account information or debit card so BaitFinder can directly deposit money in your account.',
        image: '/images/baitshop_reservation_stripe_2.png'
    }
];
const CarouselItemsReservation: ICarouselItem[] = [
    {
        body: `1. You also have the ability to accept reservations only for specific baits. (i.e.
                    you will accept reservations for Croaker or Mullet, but not Shrimp.`,
        image: '/images/baitshop_reservation_enable_1.png'
    },
    {
        body: '2. On the bait tab, select the pencil by a bait',
        image: '/images/baitshop_reservation_enable_2.png'
    },
    {
        body: '3. Choose Edit Availability',
        image: '/images/baitshop_reservation_enable_3.png'
    },
    {
        body: '4. Move the toggle to the right to enable reservations',
        image: '/images/baitshop_reservation_enable_4.png'
    },
    {
        body: '5. The Angler can tell if you are accepting reservations by the R by a bait',
        image: '/images/baitshop_reservation_enable_5.png'
    }
];
const CarouselItemsProcessingReservation: ICarouselItem[] = [
    {
        body: `1. Angler Submits`,
        image: '/images/reservation_1.png'
    },
    {
        body: '2. On the bait tab, select the pencil by a bait',
        image: '/images/reservation_2.png'
    },
    {
        body: '3. Choose Edit Availability',
        image: '/images/reservation_2.png'
    },
    {
        body: '4. Move the toggle to the right to enable reservations',
        image: '/images/reservation_2.png'
    },
    {
        body: '5. The Angler can tell if you are accepting reservations by the R by a bait',
        image: '/images/reservation_2.png'
    }
];

const BaitShopReservationPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar baitshop />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Reservations
            </StyledText>
            <Grid css={cssPage}>
                <StyledText>
                    Three things have the ability to destroy a day of fishing. 1. Weather; 2.
                    Boat/Trailer Failure; 3. Bait Availability. There are hundreds of apps which
                    help you predict the weather. With proper maintenance, boat/trailer failures are
                    exceptionally rare. BaitFinder partnering with Bait Shops we have the ability to
                    solve the challenge of Bait Availability.
                </StyledText>
                <StyledText subheading>Guides</StyledText>
                <StyledText>
                    For guides, bait availability is critical to their livelihood, we have made it
                    simple for Bait Shops to allow guides they know to reserve their bait, including
                    the ability to charge them differently.
                </StyledText>
                <StyledText subheading>Anglers</StyledText>
                <StyledText>
                    For Anglers bait is a small part of the cost of a fishing trip, especially when
                    traveling from out of town...Truck Gas, Boat Gas, Hotel, not to mention valuable
                    vacation time. After talking to hundreds of anglers around the country,
                    virtually all of them would be happy (THRILLED) to pay a little more to reduce
                    the risk of bait availability ruining their fishing trip.
                </StyledText>
                <StyledText>
                    We have designed the entire process with the Bait Shop in mind. We have address
                    concerns like "What if all the bait dies", "What if my bait supplier does not
                    show up", "What if the Guide/Angler does not show up"....and with feedback from
                    dozens of Bait Shops...all the other "What if's" have been addressed.
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 70px;
                    `}>
                    Step 1: Onboard with Stripe
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsStripe}></ResponsiveCarouselNext>

                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 50px;
                    `}>
                    Enabling Reservations
                </StyledText>
                <StyledText>
                    To enable reservations is simple, on the Info tab, simple check the box. You
                    have the option to get reservations from Only Guides or All Users.
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsReservation}></ResponsiveCarouselNext>

                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 50px;
                    `}>
                    Processing Reservations
                </StyledText>
                <StyledText textAlign='center'>
                    There are 4 steps in the reservation process.
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsProcessingReservation}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter isBaitshop />
        </div>
    );
};

BaitShopReservationPage.defaultProps = {};

export default BaitShopReservationPage;
