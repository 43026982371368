import * as React from 'react';

import SectionBanner from '../../components/SectionBanner';
import SectionFooter from '../../components/SectionFooter';
import SectionOurStory from '../../components/SectionOurStory';
import SectionReservations from '../../components/SectionReservations';

import { Grid, Hidden } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import breakpoint from '../../app_utils/Breakpoints';
import ScrollAnimation from 'react-animate-on-scroll';
import { StyledPhotoImage, StyledSection, StyledText } from '../../components_elements';
import PhotoIphone from '../../assets/images/iphone_image.png';
import Navbar from '../../components/Navbar';
import ResponsiveCarouselAuto from '../../components/ResponsiveCarouselAuto';
import ZEnrollButton from '../../components/ZEnrollButton';

interface Props {}

interface AppStoreProps {
    background: string;
    link: string;
}

const AppStore = (props: AppStoreProps) => {
    return (
        <a
            css={css`
                display: block;
                z-index: 2;
                background-image: url(${props.background});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                height: 4.5rem;
                width: 14rem;
                margin: auto;
                text-indent: -9999em;
                &:hover {
                    cursor: pointer;
                }
            `}
            href={props.link}
            target='_blank'
            rel='noopener noreferrer'>
            {props.link}
        </a>
    );
};

const SectionAppStore = () => {
    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;

        img {
            max-height: 350px;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @media ${breakpoint.md} {
                max-height: 600px;
            }
        }
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    BaitFinder and BaitFinder Admin are FREE mobile apps.
                </StyledText>

                <StyledText textAlign='center'>
                    BaitFinder and BaitFinder Admin are FREE mobile apps designed to help Bait Shops
                    connect with Anglers by providing Bait Shops the ability to publish Bait
                    Availability, Push Notifications, Publish Fishing reports, Advertise
                    Goods/Services, and Accept Pre-Paid Orders (optional)
                </StyledText>

                <StyledText subheading textAlign='center'>
                    It's free, but you must enroll your Bait Shop.
                </StyledText>

                <ZEnrollButton />

                <Hidden smDown>
                    <AppStore
                        background='/images/app_store_google.png'
                        link='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_admin'
                    />
                    <AppStore
                        background='/images/app_store_apple.png'
                        link='https://apps.apple.com/us/app/baitfinder-admin/id1480098942'
                    />
                </Hidden>
            </Grid>

            <Grid item xs={12} md={5}>
                <img src='/images/angler_home_app_section.png' alt='' />
            </Grid>
        </Grid>
    );
};

const SectionAvailability = () => {
    const images = [
        '/images/baitshop_home_avalability_1.png',
        '/images/baitshop_home_avalability_2.png',
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Publish Bait Availability
                </StyledText>
                <StyledText textAlign='center'>
                    Publishing availability allows bait shops to focus on the customer in front of
                    them vs. the customer on the phone asking if you have a specific bait.
                </StyledText>
                <StyledText textAlign='center'>
                    In less than a second, with a simple single click of a button Bait Shops can
                    publish and change their bait availability
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};
const SectionNotification = () => {
    const images = [
        '/images/baitshop_home_notification_1.png',
        '/images/baitshop_home_notification_2.png',
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Push Notifications
                </StyledText>
                <StyledText textAlign='center'>
                    Have you ever had a customer stop by and you are out of a bait, but you are
                    supposed to get more in 1-4 hours, they call every 20 min to check...there is a
                    better way!
                </StyledText>
                <StyledText textAlign='center'>
                    The GREAT PART is the Bait Shop does not have to do anything. The Angler can set
                    up a notification, once set up they will automatically get notified when the
                    bait arrives
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};

const SectionAdvertiseGoodsServices = () => {
    const images = [
        '/images/baitshop_home_goods_services_1.png',
        '/images/baitshop_home_goods_services_2.png',
        '/images/baitshop_home_goods_services_3.png',
        '/images/baitshop_home_goods_services_4.png',
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Advertise Goods/Service
                </StyledText>
                <StyledText textAlign='center'>
                    Most Bait Shops have much more to offer than bait. Fuel, tackle, fishing pier,
                    boat ramp, boat slips, charter services, fish cleaning, lodging, reel repair,
                    custom rods....you name it, you can advertise it.
                </StyledText>
                <StyledText textAlign='center'>
                    Someday, this may be a premium feature, but for 2020 it's free.
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>

            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};
const SectionFishingReports = () => {
    const images = [
        '/images/baitshop_home_fishing_report_1.png',
        '/images/baitshop_home_fishing_report_2.png',
        '/images/baitshop_home_fishing_report_3.png',
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Publish Fishing Reports
                </StyledText>
                <StyledText textAlign='center'>
                    Bait Shops are the best source of what is running, what is biting on which
                    bait...an Anglers know it.
                </StyledText>
                <StyledText textAlign='center'>
                    BaitFinder enables Bait Shops to create a following of Anglers who visit their
                    site frequently to view fishing reports.
                </StyledText>
                <StyledText textAlign='center'>
                    Anglers can set alerts to get a notification when a new report is posted.
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>

            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};
const AcceptPrePaidOrders = () => {
    const images = [
        '/images/baitshop_home_prepaid_order_1.png',
        '/images/baitshop_home_prepaid_order_2.png',
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Accept Pre-Paid Orders (Optional)
                </StyledText>
                <StyledText textAlign='center'>
                    In virtually every part of our lives we pre-pay for things or make reservations.
                    Airplanes, Hotels, Restaurants, Concerts....
                </StyledText>
                <StyledText textAlign='center'>
                    Anglers would gladly pay a fee for the ability to pre-pay for bait.
                </StyledText>
                <StyledText textAlign='center'>
                    Everything about our payment system is designed based on bait shop feedback,
                    specifically around the unpredictability of bait.
                </StyledText>
                <StyledText textAlign='center'>
                    We even designed it so Bait Shops can choose to only accept reservations from
                    guides or named loyal customers.
                </StyledText>
                <StyledText textAlign='center'>
                    Ask a BaitFinder associate to walk you through the process
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};

const BaitShopHomePage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar baitshop />
            <SectionBanner id='banner' />
            <SectionAppStore />
            <SectionAvailability />
            <SectionFishingReports />
            <SectionNotification />
            <SectionAdvertiseGoodsServices />
            <AcceptPrePaidOrders />
            <SectionOurStory />
            <SectionFooter isBaitshop />
        </div>
    );
};
export default BaitShopHomePage;
