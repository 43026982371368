/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

type IProps = {
    width?: string;
    height?: string;
};

const StyledBox = styled.div<IProps>`
    height: ${(props: IProps) => props.height && props.height};
    width: ${(props: IProps) => props.width && props.width};
`;

export default StyledBox;
