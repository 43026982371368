import * as React from 'react';
import PhotoGuyFishing from '../assets/images/guy_fishing.jpeg';
import AppStoreGoogle from '../assets/images/app_store_google.png';
import AppStoreApple from '../assets/images/app_store_apple.png';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import breakpoint from '../app_utils/Breakpoints';

interface Props {
    id?: string;
}

interface AppStoreProps {
    background: string;
    link: string;
}

const AppStore = (props: AppStoreProps) => {
    return (
        <a
            css={css`
                display: block;
                z-index: 2;
                background-image: url(${props.background});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                height: 4.5rem;
                width: 14rem;
                margin: auto;
                text-indent: -9999em;
                &:hover {
                    cursor: pointer;
                }
                @media ${breakpoint.md} {
                    display: none;
                }
            `}
            href={props.link}
            target='_blank'
            rel='noopener noreferrer'>
            {props.link}
        </a>
    );
};

const SectionBanner: React.FC<Props> = ({ id }) => {
    return (
        <div id={id}>
            <div
                css={css`
                    position: relative;
                    padding: 20px;
                    margin: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-image: url(${PhotoGuyFishing});
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    &::before {
                        content: '';
                        position: absolute;
                        top: -10%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: block;
                        background: linear-gradient(transparent, #ffffff);
                        background-image: -webkit-linear-gradient(
                            top,
                            rgba(255, 255, 255, 0) 0%,
                            #fff 85%,
                            #fff 100%
                        );
                        z-index: 1;

                        @media ${breakpoint.md} {
                            top: 40%;
                        }
                    }
                    @media ${breakpoint.md} {
                        height: 50vh;
                    }
                `}>
                <AppStore
                    background={AppStoreGoogle}
                    link='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_v2'
                />
                <AppStore
                    background={AppStoreApple}
                    link='https://apps.apple.com/us/app/baitfinder/id1478728737?ls=1'
                />
            </div>
        </div>
    );
};
export default SectionBanner;
