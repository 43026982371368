/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

type IProps = {
    maxWidth?: string | number;
    padding?: string;
    pageContainer?: boolean;
};

const StyledSection = styled.div<IProps>`
    width: 100%;
    max-width: ${(props: IProps) => (props.maxWidth ? props.maxWidth : '100%')};
    margin: ${(props: IProps) => props.maxWidth && 'auto'};
    padding: ${(props: IProps) => props.padding && props.padding};
    ${(props: IProps) =>
        props.pageContainer &&
        css`
            max-width: 1080px;
            margin: auto;
            padding: 70px 20px;
        `};
`;

export default StyledSection;
