import * as React from 'react';

import breakpoint from '../app_utils/Breakpoints';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';

import { useState } from 'react';
import { Grid, IconButton, Hidden, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

interface Props {
    angler?: boolean;
    baitshop?: boolean;
    admin?: boolean;
}
interface LinkProps {
    angler?: boolean;
    baitshop?: boolean;
    admin?: boolean;
    toggleDrawer: () => void;
}

const StyledNav = styled.div`
    background: #fff;
    box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.12);
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 10px 20px;

    .max-width {
        max-width: 1080px;
        margin: auto;
    }
`;

const StyledWrapper = styled.div`
    height: 58px;
`;

const cssLink = css`
    text-decoration: none;
    margin: 35px 10px;
    font-size: 0.95rem;
    color: black;

    &:hover {
        color: blue;
    }

    @media ${breakpoint.md} {
        text-decoration: none;
        margin: 0px 10px;
        font-size: 0.95rem;
        color: black;
    }
`;

const cssButton = css`
    text-decoration: none;
    margin: 35px 10px;
    font-size: 0.95rem;
    background-color: blue;
    color: white;
    padding: 8px 12px;
    border-radius: 30px;

    @media ${breakpoint.md} {
        margin: 0px 10px;
        background-color: #0053a3;
        padding: 8px 12px;
        border-radius: 30px;
    }
`;

const cssLinks = css`
    text-decoration: none;
    color: black;
`;
const cssLinksButton = css`
    text-decoration: none;
    color: white;
`;

const styledGrid = css`
    display: none;

    @media ${breakpoint.md} {
        display: flex;
    }
`;

const Logo = styled.div`
    img {
        height: 33px;
        width: 130px;
    }

    @media ${breakpoint.md} {
        img {
            height: 60px;
            width: 260px;
        }
    }
`;

const Anglerlinks = [
    { title: 'Availability', route: '/angler/availability', style: 'link' },
    { title: 'Notifications', route: '/angler/notifications', style: 'link' },
    { title: 'Reservations', route: '/angler/reservations', style: 'link' },
    { title: 'Fishing Reports', route: '/angler/fishing-reports', style: 'link' },

    { title: 'Bait Shops Site', route: '/baitshop', style: 'button' },
];

const BaitShoplinks = [
    { title: 'Availability', route: '/baitshop/availability', style: 'link' },
    { title: 'Fishing Reports', route: '/baitshop/fishing-reports', style: 'link' },
    { title: 'Service & Rentals', route: '/baitshop/service-rentals', style: 'link' },
    { title: 'Anglers Site', route: '/angler', style: 'button' },
    { title: 'Enroll', route: '/enroll', style: 'button' },
    { title: 'Admin Site', route: 'https://admin.baitfinder.com/', style: 'button' },
];

const LinksDesktop = ({ angler, baitshop }: Props) => {
    return (
        <Grid container justify='flex-end' alignItems='center' css={styledGrid}>
            {angler &&
                Anglerlinks.map((item, index) => (
                    <Link
                        key={index}
                        to={item.route}
                        css={item.style === 'link' ? cssLink : cssButton}>
                        <div>{item.title}</div>
                    </Link>
                ))}
            {baitshop &&
                BaitShoplinks.map((item, index) => (
                    <span css={item.style === 'link' ? cssLink : cssButton}>
                        {item.title !== 'Admin Site' && (
                            <Link
                                css={item.style === 'link' ? cssLinks : cssLinksButton}
                                key={index}
                                to={item.route}>
                                <div>{item.title}</div>
                            </Link>
                        )}
                        {item.title === 'Admin Site' && (
                            <a
                                css={css`
                                    text-decoration: none !important;
                                `}
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://admin.baitfinder.com/'>
                                <div css={cssLinksButton}>Bait Shop Admin Login</div>
                            </a>
                        )}
                    </span>
                ))}
        </Grid>
    );
};

const LinksMobile = ({ angler, baitshop, toggleDrawer }: LinkProps) => {
    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            css={css`
                width: 250px;
            `}>
            {angler &&
                Anglerlinks.map((item, index) => (
                    <Link
                        key={index}
                        to={item.route}
                        css={item.style === 'link' ? cssLink : cssButton}>
                        <div onClick={() => toggleDrawer}>{item.title}</div>
                    </Link>
                ))}
            {baitshop &&
                BaitShoplinks.map((item, index) => (
                    <Link
                        key={index}
                        to={item.route}
                        css={item.style === 'link' ? cssLink : cssButton}>
                        <div onClick={() => toggleDrawer}>{item.title}</div>
                    </Link>
                ))}
        </Grid>
    );
};

const Navbar: React.FC<Props> = ({ angler, baitshop, admin }) => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleDrawer = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <StyledWrapper>
            <StyledNav>
                <Grid className='max-width' container justify='space-between' alignItems='center'>
                    <Grid>
                        <Link to='/'>
                            <Logo>
                                <img
                                    src='/images/logo.png'
                                    alt=''
                                    css={css`
                                        height: 50px;
                                    `}
                                />
                            </Logo>
                        </Link>
                    </Grid>

                    <Grid>
                        <LinksDesktop angler={angler} baitshop={baitshop} />
                    </Grid>

                    <Hidden mdUp>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            edge='end'
                            onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>

                        <Drawer
                            anchor='right'
                            open={open}
                            onClose={toggleDrawer}
                            css={css`
                                max-width: 20%;
                            `}>
                            <LinksMobile
                                angler={angler}
                                baitshop={baitshop}
                                toggleDrawer={toggleDrawer}
                            />
                        </Drawer>
                    </Hidden>
                </Grid>
            </StyledNav>
        </StyledWrapper>
    );
};
export default Navbar;
