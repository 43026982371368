/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import { StyledText, StyledSection } from '../components_elements';
import SiteData from '../app_data';
import FishingKid from '../assets/images/fishing_kid.png';
import { Grid } from '@material-ui/core';
import breakpoint from '../app_utils/Breakpoints';

interface Props {}

const SectionOurStory: React.FC<Props> = () => {
    return (
        <StyledSection
            css={css`
                background: linear-gradient(#ffffff, #f8f8f8);
            `}>
            <StyledSection maxWidth='1440px' padding='100px 20px'>
                <StyledText
                    heading
                    textAlign='center'
                    css={css`
                        margin-bottom: 30px;

                        @media ${breakpoint.md} {
                            margin-bottom: 70px;
                        }
                    `}>
                    Our Story
                </StyledText>

                <Grid container justify='space-between' alignItems='center'>
                    <Grid item md={4} xs={12}>
                        <StyledText>{SiteData.ourStudy[0]}</StyledText>
                        <StyledText>{SiteData.ourStudy[1]}</StyledText>
                    </Grid>

                    <Grid
                        item
                        md={3}
                        xs={12}
                        css={css`
                            background: url(${FishingKid});
                            height: 600px;
                            max-height: 600px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: bottom right;
                        `}></Grid>

                    <Grid item md={4} xs={12}>
                        <StyledText>{SiteData.ourStudy[2]}</StyledText>
                        <StyledText>{SiteData.ourStudy[3]}</StyledText>
                    </Grid>
                </Grid>
            </StyledSection>
        </StyledSection>
    );
};
export default SectionOurStory;
