import * as React from 'react';
import Navbar from '../../components/Navbar';
import { Grid } from '@material-ui/core';
import { StyledText } from '../../components_elements';

import ImgFishingReport1 from '../../assets/images/iphone_landing.png';
import ImgFishingReport2 from '../../assets/images/iphone_landing.png';
import ImgFishingReport3 from '../../assets/images/iphone_landing.png';
import ImgFishingReport4 from '../../assets/images/iphone_landing.png';
import ImgFishingReport5 from '../../assets/images/iphone_landing.png';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';
import breakpoint from '../../app_utils/Breakpoints';
import ZEnrollButton from '../../components/ZEnrollButton';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 40px;
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const cssGridWrapper = css`
    padding: 100px 30px;
    max-width: 1080px;
    margin: auto;

    img {
        max-height: 350px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media ${breakpoint.md} {
            max-height: 600px;
        }
    }
`;

const CarouselItems: ICarouselItem[] = [
    {
        body: '1. Click on Reports',
        image: '/images/baitshop_fishing_report_1.png',
    },
    {
        body: '2. Click on Write',
        image: '/images/baitshop_fishing_report_2.png',
    },
    {
        body: '3. Write Report',
        image: '/images/baitshop_fishing_report_3.png',
    },
    {
        body: '4. Add a picture',
        image: '/images/baitshop_fishing_report_4.png',
    },
];

const BaitShopFishingReportPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar baitshop />

            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Fishing Reports
            </StyledText>
            <Grid css={cssPage}>
                <StyledText>
                    Fishing reports are not only fun, but an exceptionally valuable tool for Bait
                    Shops. Anglers love to read them, if a bait shop posts them regularly...anglers
                    will visit you page frequently. When it's time to buy bait, they will come to
                    your shop.!
                </StyledText>
                <StyledText>
                    You can also drive demand! Flounder running, Bass spawning, Tarpon migrating
                    through, Walleye biting on shiners.....these reports will drive anglers to fish,
                    and come to you for bait.
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 70px;
                    `}>
                    Publishing Fishing Reports is simple and easy.
                </StyledText>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: -30px;
                    `}>
                    (Admin and Associates can publish)
                </StyledText>
                <ResponsiveCarouselNext CarouselItems={CarouselItems}></ResponsiveCarouselNext>

                <ZEnrollButton />
            </Grid>

            <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
                <Grid item md={7} xs={12}>
                    <StyledText textAlign='center' subheading>
                        Anglers will follow your reports and get notified when you post one.
                    </StyledText>
                </Grid>

                <Grid item xs={12} md={5}>
                    <img src='/images/baitshop_fishing_report_bottom_1.png' alt='' />
                </Grid>
            </Grid>

            <ZEnrollButton />

            <SectionFooter isBaitshop />
        </div>
    );
};

BaitShopFishingReportPage.defaultProps = {};

export default BaitShopFishingReportPage;
