import * as React from 'react';

interface Props {}

const PrivacyPage: React.FC<Props> = () => {
    return (
        <div>
            Privacy Policy protecting your private information is our priority. This Statement of Privacy applies to www.baitfinder.com,
            BaitFinder app, and BaitFinder, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless
            otherwise noted, all references to BaitFinder, LLC include www.baitfinder.com, The BaitFinder App, and BaitFinder. The
            BaitFinder website is a eCommerce site. By using the BaitFinder website, you consent to the data practices described in this
            statement. Collection of your Personal Information In order to better provide you with products and services offered on our
            Site, BaitFinder may collect personally identifiable information, such as your: ​ First Name and Last Name Mailing Address Email
            Address Phone No ​ If you purchase BaitFinder's products and services, we collect billing and credit card information. This
            information is used to complete the purchase transaction. ​ Please keep in mind that if you directly disclose personally
            identifiable information or personally sensitive data through BaitFinder's public message boards, this information may be
            collected and used by others. ​ We do not collect any personal information about you unless you voluntarily provide it to us.
            However, you may be required to provide certain personal information to us when you elect to use certain products or services
            available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest
            sponsored by us or one of our partners; (c)signing up for special offers from selected third parties; (d) sending us an email
            message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our
            Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or
            products you have requested from us. We also may gather additional personal or non-personal information in the future. Use of
            your personal information BaitFinder collects and uses your personal information to operate its website(s) and deliver the
            services you have requested. ​ BaitFinder may also use your personally identifiable information to inform you of other products
            or services available from BaitFinder and its affiliates. Sharing Information with Third Parties BaitFinder does not sell, rent
            or lease its customer lists to third parties. ​ BaitFinder may share data with trusted partners to help perform statistical
            analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are
            prohibited from using your personal information except to provide these services to BaitFinder, and they are required to
            maintain the confidentiality of your information. ​ BaitFinder may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on BaitFinder or the site; (b) protect and defend the rights or property of BaitFinder; and/or
            (c) act under exigent circumstances to protect the personal safety of users of BaitFinder, or the public. Tracking User
            Behaviour BaitFinder may keep track of the websites and pages our users visit within BaitFinder, in order to determine what
            BaitFinder services are the most popular. This data is used to deliver customized content and advertising within BaitFinder to
            customers whose behavior indicates that they are interested in a particular subject area Automatically collected information
            Information about your computer hardware and software may be automatically collected by BaitFinder. This information can
            include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for
            the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the
            BaitFinder website. Use of Cookies The BaitFinder website may use "cookies" to help you personalize your online experience. A
            cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver
            viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued
            the cookie to you. ​ One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of
            a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize BaitFinder pages,
            or register with BaitFinder site or services, a cookie helps BaitFinder to recall your specific information on subsequent
            visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and
            so on. When you return to the same BaitFinder website, the information you previously provided can be retrieved, so you can
            easily use the BaitFinder features that you customized. ​ You have the ability to accept or decline cookies. Most Web browsers
            automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the interactive features of the BaitFinder services or websites you
            visit. Links This website contains links to other sites. Please be aware that we are not responsible for the content or privacy
            practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of
            any other site that collects personally identifiable information. Security of your personal information BaitFinder secures your
            personal information from unauthorized access, use, or disclosure. BaitFinder uses the following methods for this purpose: ​ -
            SSL Protocol ​ When personal information (such as a credit card number) is transmitted to other websites, it is protected
            through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. ​ We strive to take appropriate security
            measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data
            transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to
            protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet
            which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you
            and us through this Site cannot be guaranteed. Children Under Thirteen BaitFinder does not knowingly collect personally
            identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent
            or guardian for permission to use this website. Disconnecting your BaitFinder Account from Third Party Websites You will be able
            to connect your BaitFinder account to third party accounts. BY CONNECTING YOUR BAITFINDER ACCOUNT TO YOUR THIRD PARTY ACCOUNT,
            YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE
            WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
            IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third
            party account at any time. Users can disconnect accounts on the settings tab. E-mail Communications From time to time,
            BaitFinder may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations,
            surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an
            email from BaitFinder or click on a link therein. If you would like to stop receiving marketing or promotional communications
            via email from BaitFinder, you may opt out of such communications by Customers simply need to reply STOP. External Data Storage
            Sites We may store your data on servers provided by third party hosting vendors with whom we have contracted. Changes to this
            Statement BaitFinder reserves the right to change this Privacy Policy from time to time. We will notify you about significant
            changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by
            placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site
            and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified
            Privacy Policy; and (b) agreement to abide and be bound by that Policy. Contact Us BaitFinder welcomes your questions or
            comments regarding this Statement of Privacy. If you believe that BaitFinder has not adhered to this Statement, please contact
            BaitFinder at: BaitFinder LLC 11404 Mordred Court Austin, Texas 78739 ​ Email Address: chris.auger@baitfinder.com ​ Telephone
            number: 5123091608 ​ Effective as of September 21, 2019
        </div>
    );
};

PrivacyPage.defaultProps = {};

export default PrivacyPage;
