import * as React from 'react';
import { Grid } from '@material-ui/core';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { StyledText } from '../../components_elements';
import Navbar from '../../components/Navbar';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItemsTop: ICarouselItem[] = [
    {
        body: '1. Open a Bait Shop and mark it a favorite.',
        image: '/images/availability_top_1.png'
    },
    {
        body: '2. List your Favorites.',
        image: '/images/availability_top_2.png'
    },
    {
        body: '3. From the list of favorites you can jump to your favorite shop.',
        image: '/images/availability_top_3.png'
    },
    {
        body: '4. Now you can see all the bait your favorite bait shop has to offer.',
        image: '/images/availability_top_4.png'
    }
];
const CarouselItemsBottom: ICarouselItem[] = [
    {
        body: '1. Click on magnifying glass',
        image: '/images/availability_bottom_1.png'
    },
    {
        body: '2. Click on select search area.',
        image: '/images/availability_bottom_2.png'
    },
    {
        body: '3. Place the pin on the map.',
        image: '/images/availability_bottom_3.png'
    },
    {
        body: '4. Click on change bait choices.',
        image: '/images/availability_bottom_4.png'
    },
    {
        body: '5. Click on Fresh/Salt Water.',
        image: '/images/availability_bottom_5.png'
    },
    {
        body: '6. Click on the bait your are looking for, you can choose multiple.',
        image: '/images/availability_bottom_6.png'
    },
    {
        body: '7. Select Live|Fresh|Frozen, click on save',
        image: '/images/availability_bottom_7.png'
    },
    {
        body: `8. GREEN = Shop has everything, YELLOW = Shop has one or more, RED = Shop does not have what you are looking for`,
        image: '/images/availability_bottom_8.png'
    },
    {
        body: '9. Click on the pin, open Bait Shop for details.',
        image: '/images/availability_bottom_9.png'
    },
    {
        body: '10. View details of the bait this shop has RIGHT NOW!!.',
        image: '/images/availability_bottom_10.png'
    }
];

const AnglerAvailabilityPage: React.FC<Props> = () => {
    const cssPageIntro = css`
        max-width: 1080px;
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
        margin-top: 80px;
    `;
    const cssGridSection = css`
        padding: 80px 0px;
        background: linear-gradient(#ffffff, #f8f8f8);
    `;

    return (
        <div>
            <Navbar angler />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Availability
            </StyledText>

            <StyledText css={cssPageIntro}>
                BaitFinder provides a FREE service to both Anglers and Bait Shops. We enable bait
                shops to publish their bait availability. After a bait shop begins publishing bait
                availability, Anglers look at a specific bait shop and see what they have available
                right now, or they can search an area to see if any bait shops have a specific bait
                available.
            </StyledText>

            <Grid css={cssGridSection}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    It's simple to view a bait availability in an area! Stop HUNTING (or calling)
                    for Bait....START FISHING!!!
                </StyledText>
                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsBottom}></ResponsiveCarouselNext>
            </Grid>

            <Grid css={cssGridSection}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    Create a list of your favorite baitshops.
                </StyledText>
                <ResponsiveCarouselNext CarouselItems={CarouselItemsTop}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter />
        </div>
    );
};

AnglerAvailabilityPage.defaultProps = {};

export default AnglerAvailabilityPage;
