import * as React from 'react';

import PhotoAngler from '../../assets/images/landing_angler.jpg';
import PhotoBaitShop from '../../assets/images/landing_baitshop.jpg';
import AppStoreApple from '../../assets/images/app_store_apple.png';
import AppStoreGoogle from '../../assets/images/app_store_google.png';
import Logo from '../../assets/images/logo.png';
import IPhoto from '../../assets/images/iphone_landing.png';
import HeroTableHome from '../../assets/images/hero_tablet_home.jpg';
import { Grid, Hidden, Box } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { AppContext } from '../../models_contexts/AppContext';
import Navbar from '../../components/Navbar';
import StyledSection from '../../components_elements/StyledSection';
import { StyledText } from '../../components_elements';
import StyledBox from '../../components_elements/StyledBox';
import breakpoint from '../../app_utils/Breakpoints';

interface Props {}

const cssPageWrapper = css`
    height: 100vh;
    background-position: center;
    background-size: cover;
    overflow-y: scroll;
    overflow-x: hidden;
`;

const cssPageContainer = css`
    max-width: 1080px;
    margin: auto;
    margin-top: 0px;
    position: relative;
    padding: 20px;

    &::after {
        content: '';
        position: absolute;
        top: -40%;
        right: -12%;
        z-index: -1;
        width: 645px;
        height: 645px;
        opacity: 0.2;
        border-radius: 50%;
        background-color: #00caeb;
    }
`;

const cssImageContainer = css`
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 322px);
        left: 47%;
        z-index: -1;
        width: 645px;
        height: 645px;
        opacity: 0.2;
        border-radius: 50%;
        background-color: #00caeb;
    }

    img {
        max-height: 500px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media ${breakpoint.md} {
            margin-right: 0;
            max-height: 700px;
        }
    }
`;

const cssSubHeading = css`
    text-align: center;

    @media ${breakpoint.md} {
        text-align: left;
    }

    /* &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -85px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #f7362f;
    } */
`;

const cssHeadingText = css`
    font-size: 44px;
    line-height: 4rem;
    text-align: center;

    @media ${breakpoint.md} {
        font-size: 70px;
        line-height: 4.5rem;
        text-align: center;
    }
`;

const cssLink = css`
    background-color: #ebf2f9;
    margin: 0px 10px;
    display: inline-block;
    min-width: 170px;
    padding: 8px 20px;
    border-radius: 50px;
    color: #203650;
    font-size: 17px;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;
    transition: all 0.3s;
    border-color: #ebf2f9;
    border-width: 1px;
    border-style: solid;

    outline: none;

    &:hover {
        cursor: pointer;
        background-color: #ebf2f9;
    }

    @media ${breakpoint.md} {
        background-color: transparent;
    }
`;

const cssAdminSite = css`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    background-image: url(${PhotoBaitShop});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    width: 440px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    p {
        border-radius: 10px;
        background-color: white;
        padding: 10px 15px;
        text-decoration: none;
    }
`;

const cssBaitShopLink = css`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    background-image: url(${PhotoBaitShop});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    width: 220px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    p {
        border-radius: 10px;
        background-color: white;
        padding: 10px 15px;
        text-decoration: none;
    }
`;
const cssAnglerLink = css`
 ${cssBaitShopLink}
    background-image: url(${PhotoAngler});
`;

const HomePage: React.FC<Props> = () => {
    const { state, dispatch } = React.useContext(AppContext);

    const updateRoute = (route: string) => {
        dispatch({
            type: 'SET_APP_ROUTE',
            payLoad: route,
        });
    };

    return (
        <Grid css={cssPageWrapper}>
            <Grid css={cssPageContainer}>
                <Hidden smDown>
                    <Grid>
                        <Grid container>
                            <Grid item container md={6}>
                                <img
                                    src='/images/logo.png'
                                    alt=''
                                    css={css`
                                        height: 60px;
                                    `}
                                />
                            </Grid>

                            <Grid item container xs={12} justify='flex-end' md={6}>
                                <Grid item xs={6}>
                                    <Link to='/baitshop' css={cssLink}>
                                        For Bait Shops
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to='/angler' css={cssLink}>
                                        For Anglers
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid>
                        <Grid container>
                            <Grid item container justify='center' xs={12}>
                                <img
                                    src={Logo}
                                    alt=''
                                    css={css`
                                        height: 50px;
                                        margin-bottom: 30px;
                                    `}
                                />
                            </Grid>

                            <Grid item container justify='center' xs={12} md={6}>
                                <Grid item container justify='center' xs={6}>
                                    <Link to='/baitshop' css={cssLink}>
                                        For Bait Shops
                                    </Link>
                                </Grid>
                                <Grid item container justify='center' xs={6}>
                                    <Link to='/angler' css={cssLink}>
                                        For Anglers
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>

                <StyledBox height='40px'></StyledBox>

                <Grid container justify='center'>
                    <Grid xs={12} md={6} container justify='center'>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://apps.apple.com/us/app/baitfinder/id1478728737?ls=1'>
                            <img
                                src={AppStoreApple}
                                alt=''
                                css={css`
                                    height: 100px;
                                `}
                            />
                        </a>
                    </Grid>

                    <Grid xs={12} md={6} container justify='center'>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_v2'>
                            <img
                                src={AppStoreGoogle}
                                alt=''
                                css={css`
                                    height: 100px;
                                `}
                            />
                        </a>
                    </Grid>
                </Grid>

                <Hidden smDown>
                    <StyledBox height='50px'></StyledBox>
                </Hidden>

                <Grid container justify='space-between' alignItems='center'>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        css={css`
                            position: relative;
                        `}>
                        <StyledText heading css={cssHeadingText}>
                            Welcome to BaitFinder
                        </StyledText>

                        <StyledText>
                            BaitFinder is a FREE app for both Anglers and Bait Shops It was designed
                            to solve one most frustrating parts of fishing....Finding the Bait Shop
                            with the Bait you need, when you need it.
                        </StyledText>

                        <Hidden smDown>
                            <StyledText subheading css={cssSubHeading}>
                                Choose who you are and see more details
                            </StyledText>

                            <Grid container justify='space-between'>
                                <Grid container justify='center' alignItems='center' item xs={5}>
                                    <Link to='baitshop' css={cssBaitShopLink}>
                                        <p>Bait Shops</p>
                                    </Link>
                                </Grid>

                                <Grid container justify='center' alignItems='center' item xs={5}>
                                    <Link to='angler' css={cssAnglerLink}>
                                        <p>Anglers</p>
                                    </Link>
                                </Grid>
                            </Grid>

                            <Grid container justify='center'>
                                <a
                                    css={css`
                                        text-decoration: none !important;
                                    `}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://admin.baitfinder.com/'>
                                    <div
                                        css={css`
                                            height: 100px;
                                            width: 440px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #636e72;
                                            margin-top: 30px;
                                            border-radius: 10px;
                                            text-decoration: none !important;
                                            font-size: 22px;
                                            color: white;
                                        `}>
                                        Go to Admin Website
                                    </div>
                                </a>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid xs={12} md={6} css={cssImageContainer} item>
                        <img src={IPhoto} alt='' />
                    </Grid>

                    <Grid container justify='center'>
                        <Hidden mdUp>
                            <StyledText subheading css={cssSubHeading}>
                                Choose who you are and see more details
                            </StyledText>

                            <Grid container justify='space-between'>
                                <Grid container justify='center' alignItems='center' item xs={5}>
                                    <Link to='baitshop' css={cssBaitShopLink}>
                                        <p>Bait Shops</p>
                                    </Link>
                                </Grid>

                                <Grid container justify='center' alignItems='center' item xs={5}>
                                    <Link to='angler' css={cssAnglerLink}>
                                        <p>Anglers</p>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container justify='center'>
                                <a
                                    css={css`
                                        text-decoration: none !important;
                                    `}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://admin.baitfinder.com/'>
                                    <div
                                        css={css`
                                            height: 100px;
                                            width: 440px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #636e72;
                                            margin-top: 30px;
                                            border-radius: 10px;
                                            text-decoration: none !important;
                                            font-size: 22px;
                                            color: white;
                                        `}>
                                        Go to Admin Website
                                    </div>
                                </a>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default HomePage;
