import * as React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '../../styles/brainhubeu_react_carousel.css';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import styled from '@emotion/styled';
import { useLocation } from 'react-router';
import Navbar from '../../components/Navbar';
import StyledSection from '../../components_elements/StyledSection';
import { StyledText, StyledBox } from '../../components_elements';
import { Grid } from '@material-ui/core';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    padding-bottom: 0px;
    margin: auto;
    margin-bottom: -20px;
`;

const cssGridSection = css`
    padding: 80px 0px;
    background: linear-gradient(#ffffff, #f8f8f8);
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItemsTop: ICarouselItem[] = [
    {
        body: '1. Go into the bait shop availability, Click on the bell',
        image: '/images/notification_1.png'
    },
    {
        body: '2. You get a message confirming the notification is set',
        image: '/images/notification_2.png'
    },
    {
        body: "3. That's It...when the get they bait you get an alert!!",
        image: '/images/notification_3.png'
    }
];
const CarouselItemsBottom: ICarouselItem[] = [
    {
        body: '1. Click to go into details',
        image: '/images/notification_4.png'
    },
    {
        body: '2. Click on Notifications',
        image: '/images/notification_5.png'
    },
    {
        body: '3. Click on trash can to remove',
        image: '/images/notification_6.png'
    }
];

const AnglerNotificationPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar angler />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Notifications
            </StyledText>
            <Grid css={cssPage}>
                <StyledText>
                    Have you ever called a bait shop and they don't have the bait you want, but they
                    say "we will get some later today"...Then you call them till they answer the
                    phone and you find out they have ran out already.
                </StyledText>
                <StyledText>
                    BaitFinder enables you to set notifications, with notifications you get an alert
                    sent to your phone the second the bait shop has the bait you are looking for!!!!
                </StyledText>
            </Grid>

            <Grid css={cssGridSection}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    It's simple.
                </StyledText>
                <ResponsiveCarouselNext CarouselItems={CarouselItemsTop}></ResponsiveCarouselNext>
            </Grid>

            <Grid css={cssGridSection}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        max-width: 500px;
                        margin: auto;
                    `}>
                    You can manage all your notification with ease!
                </StyledText>
                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsBottom}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter />
        </div>
    );
};

AnglerNotificationPage.defaultProps = {};

export default AnglerNotificationPage;
