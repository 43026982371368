import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

interface Props {}

type FormData = {
    email: string;
    password: string;
};

const BaitShopLoginPage: React.FC<Props> = () => {
    const { register, errors, handleSubmit, control } = useForm<FormData>({});
    const onSubmit = (data: any) => console.log(data);
    return (
        <Grid
            css={css`
                height: 50vh;
            `}
            container
            justify='center'
            alignItems='center'>
            <Paper
                css={css`
                    padding: 20px;
                    max-width: 700px;
                    max-height: 400px;
                `}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} item>
                        <Controller
                            css={css`
                                width: 400px;
                            `}
                            as={
                                <TextField
                                    id='email'
                                    label='Email'
                                    inputRef={register({
                                        required: true,
                                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                    })}
                                    placeholder='Joe'
                                    margin='normal'
                                    variant='outlined'
                                    error={errors.email ? true : false}
                                    helperText={
                                        (errors?.email?.type === 'required' && 'This is required') ||
                                        (errors?.email?.type === 'pattern' && 'Must be an email address')
                                    }
                                />
                            }
                            name='email'
                            control={control}
                            defaultValue=''
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <Controller
                            css={css`
                                width: 400px;
                            `}
                            as={
                                <TextField
                                    id='password'
                                    label='Password'
                                    type='password'
                                    inputRef={register({ required: true })}
                                    margin='normal'
                                    variant='outlined'
                                    error={errors.password ? true : false}
                                    helperText={errors?.password?.type === 'required' && 'This is required'}
                                />
                            }
                            name='password'
                            control={control}
                            defaultValue=''
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <Button
                            css={css`
                                margin-top: 10px;
                            `}
                            variant='outlined'
                            color='primary'
                            type='submit'>
                            Submit
                        </Button>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};

BaitShopLoginPage.defaultProps = {};

export default BaitShopLoginPage;
