import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';

import HomePage from './pages/app/HomePage';
import NotFoundPage from './pages/app/NotFoundPage';
import BaitShopEnrollPage from './pages/baitshop/BaitShopEnrollPage';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import TermsOfUsePage from './pages/app/TermsOfUserPage';
import PrivacyPage from './pages/app/PrivacyPage';
import ReactGA from 'react-ga';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import BaitShopLoginPage from './pages/baitshop/BaitShopLoginPage';
import { theme } from './app_utils/MuiTheme';
import BaitShopHomePage from './pages/baitshop/BaitShopHomePage';
import AnglerHomePage from './pages/angler/AnglerHomePage';
import { AppContextProvider } from './models_contexts/AppContext';
import AnglerAvailabilityPage from './pages/angler/AnglerAvailabilityPage';
import AnglerNotificationPage from './pages/angler/AnglerNotificationPage';
import AnglerReservationPage from './pages/angler/AnglerReservationPage';
import AnglerFishingReportPage from './pages/angler/AnglerFishingReportsPage';
import StripeSuccess from './pages/app/StripeSuccess';
import BaitShopFishingReportPage from './pages/baitshop/BaitShopFishingReportsPage';
import BaitShopReservationPage from './pages/baitshop/BaitShopReservationPage';
import BaitShopAvailabilityPage from './pages/baitshop/BaitShopAvailabilityPage';
import BaitShopOrderRefundPage from './pages/baitshop/BaitShopOrderRefundPage';

import firebase from './firebase';
import BaitShopServiceRentalPage from './pages/baitshop/BaitShopServiceRentalPage';

const App: React.FC = () => {
    ReactGA.initialize('UA-155530495-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    firebase.analytics();

    return (
        <AppContextProvider>
            <MuiThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    <BrowserRouter>
                        <Switch>
                            //NOTE App pages
                            <Route exact path='/' component={HomePage} />
                            <Route exact path='/terms-of-use' component={TermsOfUsePage} />
                            <Route exact path='/privacy' component={PrivacyPage} />
                            //NOTE Bait Shops Pages
                            <Route
                                exact
                                path={[
                                    '/enroll',
                                    '/enrollca',
                                    '/baitshop/enroll',
                                    '/baitshop/enrollca',
                                ]}
                                component={BaitShopEnrollPage}
                            />
                            <Route exact path='/baitshop/login' component={BaitShopLoginPage} />
                            <Route
                                exact
                                path='/baitshop/availability'
                                component={BaitShopAvailabilityPage}
                            />
                            <Route
                                exact
                                path='/baitshop/orders-refund'
                                component={BaitShopOrderRefundPage}
                            />
                            <Route
                                exact
                                path='/baitshop/reservations'
                                component={BaitShopReservationPage}
                            />
                            <Route
                                exact
                                path='/baitshop/fishing-reports'
                                component={BaitShopFishingReportPage}
                            />
                            <Route
                                exact
                                path='/baitshop/service-rentals'
                                component={BaitShopServiceRentalPage}
                            />
                            <Route exact path='/baitshop' component={BaitShopHomePage} />
                            //NOTE Angler Pages
                            <Route exact path='/angler' component={AnglerHomePage} />
                            <Route
                                exact
                                path='/angler/availability'
                                component={AnglerAvailabilityPage}
                            />
                            <Route
                                exact
                                path='/angler/notifications'
                                component={AnglerNotificationPage}
                            />
                            <Route
                                exact
                                path='/angler/reservations'
                                component={AnglerReservationPage}
                            />
                            <Route
                                exact
                                path='/angler/fishing-reports'
                                component={AnglerFishingReportPage}
                            />
                            //NOTE Angler Pages
                            <Route exact path='/stripe-success/:id' component={StripeSuccess} />
                            //NOTE Error Page
                            <Route component={NotFoundPage} />
                        </Switch>
                    </BrowserRouter>
                </StylesProvider>
            </MuiThemeProvider>
        </AppContextProvider>
    );
};

export default App;
