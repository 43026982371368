import * as React from 'react';

import AppStoreGoogle from '../assets/images/app_store_google.png';
import AppStoreApple from '../assets/images/app_store_apple.png';
import PhotoPhone from '../assets/images/phone.png';
import PhotoEmail from '../assets/images/email.png';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import breakpoint from '../app_utils/Breakpoints';
import { Link } from 'react-router-dom';

interface Props {
    id?: string;
    isBaitshop?: boolean;
}

interface AppStoreProps {
    background: string;
    link: string;
}

const AppStore = (props: AppStoreProps) => {
    return (
        <a
            css={css`
                display: block;
                z-index: 2;
                background-image: url(${props.background});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                height: 3rem;
                width: 10rem;
                text-indent: -9999em;
                &:hover {
                    cursor: pointer;
                }
            `}
            href={props.link}
            target='_blank'
            rel='noopener noreferrer'>
            {props.link}
        </a>
    );
};

const SectionFooter: React.FC<Props> = ({ id, isBaitshop }) => {
    return (
        <div
            css={css`
                padding-top: 60px;
                padding-bottom: 40px;
                border-top: 1px solid #dbe0e1;
            `}>
            {isBaitshop && (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                    `}>
                    <AppStore
                        background={AppStoreGoogle}
                        link='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_admin'
                    />
                    <AppStore
                        background={AppStoreApple}
                        link='https://apps.apple.com/us/app/baitfinder-admin/id1480098942'
                    />
                </div>
            )}
            {!isBaitshop && (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                    `}>
                    <AppStore
                        background={AppStoreGoogle}
                        link='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_v2'
                    />
                    <AppStore
                        background={AppStoreApple}
                        link='https://apps.apple.com/us/app/baitfinder/id1478728737?ls=1'
                    />
                </div>
            )}

            <div
                css={css`
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin: 35px;

                    @media ${breakpoint.md} {
                        flex-direction: row;
                        justify-content: center;
                    }
                `}>
                <div
                    css={css`
                        display: flex;
                        margin-bottom: 20px;

                        @media ${breakpoint.md} {
                            margin-right: 20px;
                            margin-bottom: 0px;
                        }
                    `}>
                    <img
                        css={css`
                            height: 20px;
                            margin-right: 10px;
                        `}
                        src={PhotoEmail}
                        alt=''
                    />
                    <div>support@baitfinder.com</div>
                </div>

                <div
                    css={css`
                        display: flex;
                    `}>
                    <img
                        css={css`
                            height: 20px;
                            margin-right: 10px;
                        `}
                        src={PhotoPhone}
                        alt=''
                    />
                    <div>512 240-2241</div>
                </div>
            </div>
            <div
                css={css`
                    margin: auto;
                    text-align: center;
                `}>
                <Link
                    css={css`
                        color: #000000;
                        text-decoration: none;
                        font-size: 0.75rem;
                        font-weight: 300;
                        padding: 0 0.625rem;
                        position: relative;
                        transition: color 0.2s ease-in-out;
                        &::after {
                            position: absolute;
                            content: '';
                            right: -0.3125rem;
                            top: 50%;
                            transform: translateY(-50%) translateX(-50%);
                            width: 0.1875rem;
                            height: 0.1875rem;
                            display: block;
                            background-color: #000000;
                            border-radius: 50%;
                        }
                    `}
                    to='/terms-of-use'>
                    Terms of use
                </Link>

                <Link
                    css={css`
                        color: #000000;
                        text-decoration: none;
                        font-size: 0.75rem;
                        font-weight: 300;
                        padding: 0 0.625rem;
                        position: relative;
                        transition: color 0.2s ease-in-out;
                    `}
                    to='/privacy'>
                    Privacy
                </Link>
            </div>
            <div>
                <div
                    css={css`
                        margin-top: 1.875rem;
                        padding-bottom: 1.5625rem;
                        text-align: center;
                        color: #949494;
                        font-size: 0.7rem;
                        font-weight: 300;
                    `}>
                    © 2020 Baitfinder
                </div>
            </div>
        </div>
    );
};

SectionFooter.defaultProps = {
    id: 'availability'
};
export default SectionFooter;
