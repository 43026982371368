import React, { Dispatch } from 'react';

interface IState {
    appRoute: string;
}
interface IAction {
    type?: string;
    payLoad?: any;
}
interface IContextProps {
    state: IState;
    dispatch: Dispatch<IAction>;
}

const initialState: IState = {
    appRoute: 'anglers'
};

function reducer(state: IState, action: IAction): IState {
    switch (action.type) {
        case 'SET_APP_ROUTE':
            console.log(state.appRoute);
            return { ...state, appRoute: action.payLoad };
        default:
            return state;
    }
}

export const AppContext = React.createContext({} as IContextProps);

export function AppContextProvider(props: any) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
}
