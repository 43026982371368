import * as React from 'react';
import Navbar from '../../components/Navbar';
import { Container, Grid, Typography } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import StyledText from '../../components_elements/StyledText';

import ImgFishingReport1 from '../../assets/images/iphone_landing.png';
import ResponsiveCarouselNext from '../../components/ResponsiveCarouselNext';
import SectionFooter from '../../components/SectionFooter';

interface Props {}

const cssPage = css`
    max-width: 1080px;
    padding: 40px 20px;
    margin: auto;
    margin-bottom: 40px;
`;

interface ICarouselItem {
    image: string;
    body: string;
}

const CarouselItemsStripe: ICarouselItem[] = [
    {
        body:
            '1. Stripe is the largest processor of credit cards in the world.  They have a simple intuitive way to create an account and enable BaitFinder to deposit money directly in your account.',
        image: '/images/reservation_1.png'
    },
    {
        body:
            '2. On the Info tab of the admin app, click Link, this launches a Stripe powered wizard, which will ask you a few simple questions, and either bank account information or debit card so BaitFinder can directly deposit money in your account.',
        image: '/images/reservation_2.png'
    }
];
const CarouselItemsReservation: ICarouselItem[] = [
    {
        body: `1. You also have the ability to accept reservations only for specific baits. (i.e.
                    you will accept reservations for Croaker or Mullet, but not Shrimp.`,
        image: '/images/reservation_1.png'
    },
    {
        body: '2. On the bait tab, select the pencil by a bait',
        image: '/images/reservation_2.png'
    },
    {
        body: '3. Choose Edit Availability',
        image: '/images/reservation_2.png'
    },
    {
        body: '4. Move the toggle to the right to enable reservations',
        image: '/images/reservation_2.png'
    },
    {
        body: '5. The Angler can tell if you are accepting reservations by the R by a bait',
        image: '/images/reservation_2.png'
    }
];
const CarouselItemsProcessingReservation: ICarouselItem[] = [
    {
        body: `1. You also have the ability to accept reservations only for specific baits. (i.e.
                    you will accept reservations for Croaker or Mullet, but not Shrimp.`,
        image: '/images/reservation_1.png'
    },
    {
        body: '2. On the bait tab, select the pencil by a bait',
        image: '/images/reservation_2.png'
    },
    {
        body: '3. Choose Edit Availability',
        image: '/images/reservation_2.png'
    },
    {
        body: '4. Move the toggle to the right to enable reservations',
        image: '/images/reservation_2.png'
    },
    {
        body: '5. The Angler can tell if you are accepting reservations by the R by a bait',
        image: '/images/reservation_2.png'
    }
];

const BaitShopOrderRefundPage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar baitshop />
            <StyledText
                textAlign='center'
                heading
                css={css`
                    background-image: url('/images/header_background.png');
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -5px;
                `}>
                Orders & Refunds
            </StyledText>
            <Grid css={cssPage}>
                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 10px;
                    `}>
                    Canceling Before Confirmation
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsStripe}></ResponsiveCarouselNext>

                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 50px;
                    `}>
                    Enabling Reservations
                </StyledText>
                <StyledText>
                    To enable reservations is simple, on the Info tab, simple check the box. You
                    have the option to get reservations from Only Guides or All Users.
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsReservation}></ResponsiveCarouselNext>

                <StyledText
                    subheading
                    textAlign='center'
                    css={css`
                        margin-top: 50px;
                    `}>
                    Enabling Reservations
                </StyledText>
                <StyledText>
                    To enable reservations is simple, on the Info tab, simple check the box. You
                    have the option to get reservations from Only Guides or All Users.
                </StyledText>

                <ResponsiveCarouselNext
                    CarouselItems={CarouselItemsReservation}></ResponsiveCarouselNext>
            </Grid>

            <SectionFooter isBaitshop />
        </div>
    );
};

BaitShopOrderRefundPage.defaultProps = {};

export default BaitShopOrderRefundPage;
