import * as React from 'react';

import SectionBanner from '../../components/SectionBanner';
import SectionFooter from '../../components/SectionFooter';
import SectionOurStory from '../../components/SectionOurStory';

import Navbar from '../../components/Navbar';
import { Grid, Hidden } from '@material-ui/core';
import StyledText from '../../components_elements/StyledText';
import ResponsiveCarouselAuto from '../../components/ResponsiveCarouselAuto';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import breakpoint from '../../app_utils/Breakpoints';

interface Props {}

interface AppStoreProps {
    background: string;
    link: string;
}

const AppStore = (props: AppStoreProps) => {
    return (
        <a
            css={css`
                display: block;
                z-index: 2;
                background-image: url(${props.background});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                height: 4.5rem;
                width: 14rem;
                margin: auto;
                text-indent: -9999em;
                &:hover {
                    cursor: pointer;
                }
            `}
            href={props.link}
            target='_blank'
            rel='noopener noreferrer'>
            {props.link}
        </a>
    );
};

const SectionAppStore = () => {
    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;

        img {
            max-height: 350px;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @media ${breakpoint.md} {
                max-height: 600px;
            }
        }
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    BaitFinder is a FREE app for Anglers
                </StyledText>
                <StyledText textAlign='center'>
                    BaitFinder is an app which enables Anglers to find Bait Shops.
                </StyledText>
                <StyledText textAlign='center'>
                    BaitFinder enables Bait Shops to publish Real-Time bait availability for FREE!
                </StyledText>
                <StyledText textAlign='center'>
                    BaitFinder enables Anglers to search published real-time bait availability.
                </StyledText>

                <Hidden smDown>
                    <AppStore
                        background='/images/app_store_google.png'
                        link='https://play.google.com/store/apps/details?id=com.availabilityfindersllc.baitfinder_v2'
                    />
                    <AppStore
                        background='/images/app_store_apple.png'
                        link='https://apps.apple.com/us/app/baitfinder/id1478728737?ls=1'
                    />
                </Hidden>
            </Grid>

            <Grid item xs={12} md={5}>
                <img src='/images/angler_home_app_section.png' alt='' />
            </Grid>
        </Grid>
    );
};
const SectionAvailability = () => {
    const images = [
        '/images/angler_home_avalability_2.png',
        '/images/angler_home_avalability_3.png'
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Real-time Bait Availability
                </StyledText>
                <StyledText textAlign='center'>
                    Anyone who fishes with live bait has had the challenge of finding the bait they
                    are looking for, or spent hours hunting for it. Stop HUNTING, start FISHING. We
                    let you know which bait Shop carries your bait and if they have it in stock.
                    Updated real-time!
                </StyledText>
                <StyledText textAlign='center'>Green = In Stock</StyledText>
                <StyledText textAlign='center'>Yellow = Partially In Stock</StyledText>
                <StyledText textAlign='center'>Red = Out of Stock</StyledText>
                <StyledText textAlign='center'>Gray = They don't stock</StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};
const SectionFishingReports = () => {
    const images = [
        '/images/angler_home_fishing_report_1.png',
        '/images/angler_home_fishing_report_2.png'
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Fishing reports
                </StyledText>
                <StyledText textAlign='center'>
                    Bait Shops are often the best source of information regarding what is biting
                    where, and on what. Follow your favorite bait Shops' latest fishing reports so
                    you are prepared for your day on the water. Or just because you need to know!
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>

            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};
const SectionSpacialOffers = () => {
    const images = [
        '/images/angler_home_services_1.png',
        '/images/angler_home_services_2.png',
        '/images/angler_home_services_3.png'
    ];

    const cssGridWrapper = css`
        padding: 50px 30px;
        max-width: 1080px;
        margin: auto;
    `;

    return (
        <Grid container justify='center' alignItems='center' css={cssGridWrapper}>
            <Hidden smDown>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <StyledText subheading textAlign='center'>
                    Special Offers and/or Product and Service Information
                </StyledText>
                <StyledText textAlign='center'>
                    Bait Shops have the ability to let you know what they have to offer. Whether its
                    fuel, ice, food, tackle, gear, fishing licences, a fishing pier or boat ramp.
                </StyledText>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} md={5}>
                    <ResponsiveCarouselAuto images={images}></ResponsiveCarouselAuto>
                </Grid>
            </Hidden>
        </Grid>
    );
};

const AnglerHomePage: React.FC<Props> = () => {
    return (
        <div>
            <Navbar angler />
            <SectionBanner id='banner' />
            <SectionAppStore />
            <SectionAvailability />
            <SectionFishingReports />
            <SectionSpacialOffers />
            <SectionOurStory />
            <SectionFooter />
        </div>
    );
};
export default AnglerHomePage;
