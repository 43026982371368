import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import breakpoint from '../app_utils/Breakpoints';

interface Props {
    image: string;
    alt?: string;
}

const StyledPhotoImage: React.FC<Props> = ({ image, alt }) => {
    return (
        <div>
            <img
                src={image}
                alt=''
                css={css`
                    max-height: 500px;
                    max-width: 100%;

                    @media ${breakpoint.md} {
                        max-height: 1000px;
                        max-width: 100%;
                    }
                `}
            />
        </div>
    );
};

StyledPhotoImage.defaultProps = {};

export default StyledPhotoImage;
